<template>

    <div class="human-body-graph">
    
        <div class="desktop" v-if="!mobile">
            <div class="graph"></div>
            <ul ref="markersDesktop">
                <li :class="{'selected' : pos === idx}" v-for="(dat,idx) in data" :key="idx">
                    <div><p>{{ dat.ft }} FT &ensp; &#8212;</p><p>{{ dat.m }} M</p></div><div class="marker"></div>
                </li>
            </ul>
            <div class="pointer" ref="point"><div class="point"></div><div class="arrow"></div><img src="@/assets/images/human-body/img-graph-pointer.webp" class="shadow"></div>
        </div>

        <div class="mobile" v-if="mobile">
            <div class="graph"></div>
            <ul ref="markersMobile">
                <li :class="{'selected' : pos === idx}" v-for="(dat,idx) in data" :key="idx">
                    <div><p>{{ dat.ft }} FT / {{ dat.m }} M</p></div><div class="marker"></div>
                </li>
            </ul>
            <div class="pointer" ref="point"><div class="point"></div><div class="arrow"></div><img src="@/assets/images/human-body/img-graph-pointer.webp" class="shadow"></div>
        </div>
        
    </div>

</template>

<script>

    import gsap from 'gsap'

    export default {
    
        name: 'HumanBodyGraph',

        components: {
            
        },

        props: [ 'data', 'pos' ],

        data() {
            return {
                componentName: this.$options.name + ' Component',
                mobile: false,
                pointerY: 0,
                newY: 0,
                curY: 0,
                pointerTimeline: null,
            };
        },
        
        created() {
            if (typeof screen.orientation === 'undefined') {
                if(window.screen.width <= 576) {
                    this.mobile = true
                }
            }
        },
        
        mounted() {
            for(var i = 0;i < this.data.length;i++) {
                if(!this.mobile) {
                    this.$refs.markersDesktop.childNodes[i].childNodes[1].style.backgroundColor = this.data[i].color
                } else {
                    this.$refs.markersMobile.childNodes[i].childNodes[1].style.backgroundColor = this.data[i].color
                }
            }
            this.$refs.point.childNodes[0].style.backgroundColor = this.data[0].color
            this.$refs.point.childNodes[1].style.borderRight = '14px solid '+ this.data[this.pos].color
            let el = this
            window.addEventListener('resize',function(){
                el.setPointer()
            })
            window.addEventListener("orientationchange", function() {
                el.setPointer()
            })
        },

        computed: {
            getClass: function() {
                let el = ''
                if(this.pos === 0) {
                    el = 'zero'
                }
                if(this.pos === 1) {
                    el = 'one'
                }
                if(this.pos === 2) {
                    el = 'two'
                }
                if(this.pos === 3) {
                    el = 'three'
                }
                if(this.pos === 4) {
                    el = 'four'
                }
                if(this.pos === 5) {
                    el = 'five'
                }
                return el
            },
            getNextY: function() {
                const { markersDesktop, markersMobile } = this.$refs;
                let nextY
                if(!this.mobile) {
                    nextY = markersDesktop.childNodes[0].childNodes[1].offsetTop - markersDesktop.childNodes[this.pos].childNodes[1].offsetTop
                } else {
                    nextY = markersMobile.childNodes[0].childNodes[1].offsetTop - markersMobile.childNodes[this.pos].childNodes[1].offsetTop
                }
                return nextY
            }
        },
        
        methods: {
            /**
             * @function setPointer
             * @description
             * 
             */
            setPointer() {
                if(this.pointerTimeline !== null) {
                    this.pointerTimeline.kill()
                    this.pointerTimeline = null
                }
                let nextY = 0
                if(!this.mobile) {
                    nextY = this.$refs.markersDesktop.childNodes[0].childNodes[1].offsetTop - this.$refs.markersDesktop.childNodes[this.pos].childNodes[1].offsetTop
                } else {
                    nextY = this.$refs.markersMobile.childNodes[0].childNodes[1].offsetTop - this.$refs.markersMobile.childNodes[this.pos].childNodes[1].offsetTop
                }
                this.pointerY = nextY
                this.$refs.point.style.transform = 'translateY('+-nextY+'px)'
            }
        },

        updated() {
            const { point } = this.$refs;

            this.curY = this.pointerY

            this.pointerY = this.getNextY

            if(!this.mobile) {
                this.newY = Math.round(this.pointerY - 1)
            } else {
                this.newY = Math.round(this.pointerY)
            }
            if(this.pos === 0) {
                this.newY = 0
            }

            this.pointerTimeline = gsap.timeline()

            this.pointerTimeline.set([
                point
            ],{
                y: -this.curY
            })
            
            this.pointerTimeline.to([
                point
            ], 10,{
                y: -this.newY,
                ease: 'power1.inOut'
            })

            gsap.to([
                point.childNodes[0]
            ], 10,{
                backgroundColor: this.data[this.pos].color,
                ease: 'power1.in'
            })

            gsap.to([
                point.childNodes[1]
            ], 10,{
                borderRight: '14px solid '+ this.data[this.pos].color,
                ease: 'power1.in'
            })
        }
        
    }

</script>

<style lang="scss" scoped>

    .human-body-graph {
        position: absolute;
        height: 40%;
        bottom: 185px;
        right: 15px;
        
        @media (min-width: 576px) { // RED (SM)
            height: 55%;
            right: 39px;
        }
        @media (min-width: 768px) { // GREEN (MD)

        }
        @media (min-width: 992px) { // BLUE (LG)
            height: 70%;
            bottom: 118px;
        }
        @media (min-width: 1200px) { // YELLOW (XL)

        }
        @media (min-width: 1400px) { // PURPLE (XXL)

        }

        & .mobile,
        & .desktop {
            height: 100%;

            & .graph {
                background: linear-gradient(to bottom,
                    #712be8 0%,
                    #5060d7 15%,
                    #49b6e7 35%,
                    #53e13f 58%,
                    #f0e923 80%,
                    #fbc208 100%
                );
                width: 22px;
                height: 100%;
                border: 4px solid white;
                border-radius: 11px;
                position: absolute;
                right: 0;
                filter: drop-shadow(0px 0px 10px rgba(0,0,0,.3));
            }

            & ul {
                list-style: none;
                position: relative;
                width: 100%;
                height: 100%;
                margin: 0;
                padding: 0;
                display: flex;
                flex-direction: column-reverse;
                justify-content: space-between;

                & li {
                    color: #101f96;
                    font-size: 12px;
                    font-weight: $font-weight-semibold;
                    line-height: 12px;
                    text-align: right;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    @media (min-width: 576px) { // RED (SM)
                        font-size: 14px;
                    }
                    @media (min-width: 768px) { // GREEN (MD)

                    }
                    @media (min-width: 992px) { // BLUE (LG)

                    }
                    @media (min-width: 1200px) { // YELLOW (XL)

                    }
                    @media (min-width: 1400px) { // PURPLE (XXL)

                    }

                    & div:nth-child(1) {
                        margin: 0 10px 0 0;

                        @media (min-width: 576px) { // RED (SM)
                            margin: 10px 0 0 0;
                        }
                        @media (min-width: 768px) { // GREEN (MD)

                        }
                        @media (min-width: 992px) { // BLUE (LG)

                        }
                        @media (min-width: 1200px) { // YELLOW (XL)

                        }
                        @media (min-width: 1400px) { // PURPLE (XXL)

                        }
                    }

                    & p {
                        margin: 0;
                    }

                    & p:nth-child(2) {
                        display: inline-block;
                        font-size: 11px;
                        margin-right: 25px;
                    }

                    & .marker {
                        background: red;
                        width: 28px;
                        height: 28px;
                        border: 6px solid white;
                        border-radius: 100%;
                        display: inline-block;
                        margin-right: -3px;
                    }

                    &:nth-child(6) {
                        flex-basis: 0%;
                        margin-top: -5px;
                    }

                    &:nth-child(5) {
                        flex-basis: 0%;
                    }

                    &:nth-child(4) {
                        flex-basis: 25%;
                    }

                    &:nth-child(3) {
                        flex-basis: 20%;
                    }

                    &:nth-child(2) {
                        flex-basis: 20%;
                    }

                    &:nth-child(1) {
                        margin-bottom: -5px;
                    }

                    &.selected {
                        & p {
                            font-weight: $font-weight-bold;
                        }
                    }
                }
            }

            & .pointer {
                position: absolute;
                bottom: -6px;
                right: -4px;
                width: 35px;
                height: 29px;
                display: flex;
                align-items: center;

                @media (min-width: 576px) { // RED (SM)
                    bottom: -8px;
                    right: -10px;
                    width: 52px;
                    height: 43px;
                }
                @media (min-width: 768px) { // GREEN (MD)

                }
                @media (min-width: 992px) { // BLUE (LG)

                }
                @media (min-width: 1200px) { // YELLOW (XL)

                }
                @media (min-width: 1400px) { // PURPLE (XXL)

                }

                & .point {
                    background: red;
                    position: absolute;
                    right: 0;
                    width: 29px;
                    height: 29px;
                    border: 2px solid white;
                    border-radius: 100%;

                    @media (min-width: 576px) { // RED (SM)
                        width: 43px;
                        height: 43px;
                        border: 3px solid white;
                    }
                    @media (min-width: 768px) { // GREEN (MD)

                    }
                    @media (min-width: 992px) { // BLUE (LG)

                    }
                    @media (min-width: 1200px) { // YELLOW (XL)

                    }
                    @media (min-width: 1400px) { // PURPLE (XXL)

                    }
                }

                & .arrow {
                    position: relative;
                    width: 0; 
                    height: 0; 
                    border-top: 5px solid transparent;
                    border-bottom: 5px solid transparent; 
                    border-right: 14px solid blue;
                }

                & .shadow {
                    position: absolute;
                    right: -1px;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    opacity: .15;

                    @media (min-width: 576px) { // RED (SM)
                        right: -2px;
                    }
                    @media (min-width: 768px) { // GREEN (MD)

                    }
                    @media (min-width: 992px) { // BLUE (LG)

                    }
                    @media (min-width: 1200px) { // YELLOW (XL)

                    }
                    @media (min-width: 1400px) { // PURPLE (XXL)

                    }
                }
            }

        }
        
    }

</style>