<template>

    <div class="human-body-info">
    
        <div class="frameGroup">
            <div class="frame1"><span class="frameBorder" ref="frame1"></span><p>{{ data[pos].oxygen }} oxygen</p></div>
            <div class="peak" :class="getClass"></div>
        </div>

        <div class="frame2">
            <span class="frameBorder" ref="frame2"></span>
            <div class="fade"></div>
            <div class="heading">
                <p class="header">Resting Heart Rate</p>
                <p>{{ data[pos].bpm }} bpm</p>
            </div>
            <div class="divider" v-if="open"></div>
            <p class="info" v-if="open">{{ data[pos].info }}</p>
            <p class="congrats" v-if="pos === 5 && open">{{ congrats }}</p>
            <button @click="toggleOpen" :class="getOpen"></button>
        </div>
        
    </div>

</template>

<script>

    export default {
    
        name: 'HumanBodyInfo',

        components: {
            
        },

        props: [ 'data', 'pos' ],

        data() {
            return {
                componentName: this.$options.name + ' Component',
                congrats: 'Congratulations! You made it halfway. Now you have to make it back down.',
                open: false,
                mobile: false,
                android: /(android)/i.test(navigator.userAgent)
            };
        },
        
        created() {
            
        },
        
        mounted() {
            this.$refs.frame1.style.border = '3px solid ' + this.data[this.pos].color
            this.$refs.frame2.style.border = '3px solid ' + this.data[this.pos].color
            if ((typeof screen.orientation === 'undefined' && window.screen.width <= 576) || this.android) { 
                this.mobile = true
            } else {
                this.open = true
            }
        },

        computed: {
            getClass: function() {
                let el = ''
                if(this.pos === 0) {
                    el = 'zero'
                }
                if(this.pos === 1) {
                    el = 'one'
                }
                if(this.pos === 2) {
                    el = 'two'
                }
                if(this.pos === 3) {
                    el = 'three'
                }
                if(this.pos === 4) {
                    el = 'four'
                }
                if(this.pos === 5) {
                    el = 'five'
                }
                return el
            },
            getOpen: function() {
                let el = !this.open ? 'open' : 'close'
                return el
            }
        },
        
        methods: {
            /**
             * @function toggleOpen
             * @description
             * 
             */
            toggleOpen: function() {
                this.open = !this.open
            }
        }
        
    }

</script>

<style lang="scss" scoped>

    .human-body-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;

        @media (max-height: 800px) {
            margin: 50px 0 0 400px;
        }
        
        @media (max-height: 700px) {
            margin: 0 0 0 400px;
        } 

        @media (min-width: 576px) { // RED (SM)
            
        }   
        @media (min-width: 768px) { // GREEN (MD)

        }
        @media (min-width: 992px) and (min-height: 800px) { // BLUE (LG)
            margin: 100px 0 0 400px;
        }
        @media (min-width: 1200px) { // YELLOW (XL)

        }
        @media (min-width: 1400px) { // PURPLE (XXL)

        }

        & .frameGroup {
            position: absolute;
            right: 20px;
            top: 15%;
            display: flex;
            justify-content: center;

            @media only screen and (max-device-width : 360px) {
                top: 9%;
            }

            @media only screen and (-webkit-device-pixel-ratio: 2) {
                top: 6.5%;
            }

            @media only screen and (-webkit-device-pixel-ratio: 2) and (device-height: 896px) {
                top: 20%;
            }

            @media only screen and (-webkit-min-device-pixel-ratio: 3) and (device-height: 896px) {
                top: 20.5%;
            }

            @media (min-width: 576px) { // RED (SM)
                right: 55px;
                top: 15%;
            }
            @media (min-width: 768px) { // GREEN (MD)
                
            }
            @media (min-width: 992px) { // BLUE (LG)
                position: relative;
                right: 0;
                top: 0;
            }
            @media (min-width: 1200px) { // YELLOW (XL)

            }
            @media (min-width: 1400px) { // PURPLE (XXL)

            }
        }

        & .peak {
            background-image: url('../../assets/images/human-body/img-peak-route.webp');
            background-size: 856px 85px;
            width: 143px;
            height: 85px;
            position: relative;
            left: 7px;

            @media (min-width: 576px) { // RED (SM)
                background-size: 1068px 106px;
                width: 178px;
                height: 106px;
                left: 10px;
            }
            @media (min-width: 768px) { // GREEN (MD)

            }
            @media (min-width: 992px) { // BLUE (LG)

            }
            @media (min-width: 1200px) { // YELLOW (XL)

            }
            @media (min-width: 1400px) { // PURPLE (XXL)

            }
        }

        & .zero {
            background-position: 0px 0px;
        }

        & .one {
            background-position: -143px 0px;

            @media (min-width: 576px) { // RED (SM)
                background-position: -178px 0px;
            }
            @media (min-width: 768px) { // GREEN (MD)

            }
            @media (min-width: 992px) { // BLUE (LG)

            }
            @media (min-width: 1200px) { // YELLOW (XL)

            }
            @media (min-width: 1400px) { // PURPLE (XXL)

            }
        }

        & .two {
            background-position: -286px 0px;

            @media (min-width: 576px) { // RED (SM)
                background-position: -356px 0px;
            }
            @media (min-width: 768px) { // GREEN (MD)

            }
            @media (min-width: 992px) { // BLUE (LG)

            }
            @media (min-width: 1200px) { // YELLOW (XL)

            }
            @media (min-width: 1400px) { // PURPLE (XXL)

            }
        }

        & .three {
            background-position: -429px 0px;

            @media (min-width: 576px) { // RED (SM)
                background-position: -534px 0px;
            }
            @media (min-width: 768px) { // GREEN (MD)

            }
            @media (min-width: 992px) { // BLUE (LG)

            }
            @media (min-width: 1200px) { // YELLOW (XL)

            }
            @media (min-width: 1400px) { // PURPLE (XXL)

            }
        }

        & .four {
            background-position: -572px 0px;

            @media (min-width: 576px) { // RED (SM)
                background-position: -712px 0px;
            }
            @media (min-width: 768px) { // GREEN (MD)

            }
            @media (min-width: 992px) { // BLUE (LG)

            }
            @media (min-width: 1200px) { // YELLOW (XL)

            }
            @media (min-width: 1400px) { // PURPLE (XXL)

            }
        }

        & .five {
            background-position: -715px 0px;

            @media (min-width: 576px) { // RED (SM)
                background-position: -890px 0px;
            }
            @media (min-width: 768px) { // GREEN (MD)

            }
            @media (min-width: 992px) { // BLUE (LG)

            }
            @media (min-width: 1200px) { // YELLOW (XL)

            }
            @media (min-width: 1400px) { // PURPLE (XXL)

            }
        }

        & .frame2,
        & .frame1 {
            border-radius: 2px;
            width: 155px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            margin-top: 52px;
            color: #101f96;
            font-size: 12px;
            font-weight: $font-weight-medium;
            border: 2px solid white;
            z-index: 1;

            @media (min-width: 576px) { // RED (SM)
                width: 210px;
                height: 40px;
                margin-top: 65px;
                font-size: 14px;
            }
            @media (min-width: 768px) { // GREEN (MD)

            }
            @media (min-width: 992px) { // BLUE (LG)

            }
            @media (min-width: 1200px) { // YELLOW (XL)

            }
            @media (min-width: 1400px) { // PURPLE (XXL)

            }

            & p {
                display: block;
                position: absolute;
                margin: 0;
                text-transform: uppercase;
            }

            & .frameBorder {
                border: 3px solid #f4be1d;
                position: absolute;
                width: 100%;
                height: 100%;
            }

            &::before {
                content: '';
                background: white;
                border-radius: 2px;
                width: 100%;
                height: 100%;
                padding: 10px;
                display: block;
                position: absolute;
                opacity: .95;

                @media (min-width: 576px) { // RED (SM)
                    padding: 20px;
                    opacity: .8;
                }
                @media (min-width: 768px) { // GREEN (MD)

                }
                @media (min-width: 992px) { // BLUE (LG)

                }
                @media (min-width: 1200px) { // YELLOW (XL)

                }
                @media (min-width: 1400px) { // PURPLE (XXL)

                }
            }
        }

        & .frame2 {
            height: auto;
            padding: 5px 15px;
            margin-top: 120px;
            margin-left: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: fixed;
            top: auto;
            left: 3.5%;
            bottom: 6px;
            width: 93%;

            @media (min-width: 576px) { // RED (SM)
                
            }
            @media (min-width: 768px) { // GREEN (MD)
                
            }
            @media (min-width: 992px) { // BLUE (LG)
                position: absolute;
                bottom: initial;
                top: initial;
                left: unset;
                width: 210px;
                padding: 5px 12px;
            }
            @media (min-width: 1200px) { // YELLOW (XL)

            }
            @media (min-width: 1400px) { // PURPLE (XXL)

            }

            & .fade {
                background: linear-gradient(to top,  rgba(255,255,255,1) 15%,rgba(255,255,255,0) 100%);
                position: fixed;
                bottom: 6px;
                left: 0;
                width: 100%;
                height: 70px;
                display: block;

                @media (min-width: 576px) { // RED (SM)
                    
                }
                @media (min-width: 768px) { // GREEN (MD)

                }
                @media (min-width: 992px) { // BLUE (LG)
                    display: none;
                }
                @media (min-width: 1200px) { // YELLOW (XL)

                }
                @media (min-width: 1400px) { // PURPLE (XXL)

                }
            }

            & .heading {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                margin-top: 7px;

                @media (min-width: 576px) { // RED (SM)
                    
                }
                @media (min-width: 768px) { // GREEN (MD)

                }
                @media (min-width: 992px) { // BLUE (LG)
                    display: block;
                    margin-top: 0;
                }
                @media (min-width: 1200px) { // YELLOW (XL)

                }
                @media (min-width: 1400px) { // PURPLE (XXL)

                }
            }

            & p {
                font-size: 20px;
                position: relative;
                line-height: 100%;
            }

            & p.header {
                color: #f1585b;
                font-size: 12px;
                font-weight: $font-weight-semibold;
                margin: 0;
                display: flex;
                align-items: center;

                @media (min-width: 576px) { // RED (SM)
                    
                }
                @media (min-width: 768px) { // GREEN (MD)

                }
                @media (min-width: 992px) { // BLUE (LG)
                    margin: 10px 0 0;
                }
                @media (min-width: 1200px) { // YELLOW (XL)

                }
                @media (min-width: 1400px) { // PURPLE (XXL)

                }

                &::before {
                    content: '';
                    display: inline-block;
                    background: transparent url('../../assets/images/human-body/img-heart.webp') 0 0 no-repeat;
                    background-size: cover;
                    width: 25px;
                    height: 22px;
                    margin-right: 5px;
                }
            }

            & .divider {
                background: #f1585b;
                width: 55%;
                height: 1px;
                margin: 10px 0 0;
                z-index: 1;
            }

            & p.congrats,
            & p.info {
                color: #4d5384;
                font-size: 14px;
                text-transform: none;
                margin: 10px 0;
                line-height: 20px;
            }

            & p.congrats {
                color: #6640f8;
                font-weight: $font-weight-bold;
            }

            & button {
                position: relative;
                background-image: url('../../assets/images/human-body/img-button-details.webp');
                background-size: 194px 66px;
                border: none;
                width: 194px;
                height: 33px;
                cursor: pointer;
                margin: 7px 0;
                display: block;

                @media (min-width: 576px) { // RED (SM)
                    
                }
                @media (min-width: 768px) { // GREEN (MD)

                }
                @media (min-width: 992px) { // BLUE (LG)
                    display: none;
                }
                @media (min-width: 1200px) { // YELLOW (XL)

                }
                @media (min-width: 1400px) { // PURPLE (XXL)

                }

                &.open {
                    background-position: 0 0;
                }

                &.close {
                    background-position: 0 -33px;
                }
            }
        }
    }

</style>