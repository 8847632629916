<template>

    <div class="human-body-experience">
        
        <div class="vidFull">
            <div class="vidWrap">
                <video class="vid" ref="vid" poster="" playsinline muted>
                    <source src="@/assets/video/human-body/vid-hiker.mp4" type="video/mp4">
                </video>
                <div class="cover" ref="cover"></div>
            </div>
        </div>

        <div class="cover"></div>

        <div class="particlesContainer"><div class="particles" ref="particles"></div></div>

        <div class="bordColor" ref="borderColor"></div>
        
        <transition name="fade" mode="in-out">
            <human-body-graph :pos="pos" :data="data"></human-body-graph>
        </transition>

        <transition name="fade" mode="out-in">
            <div class="ui" v-if="!playing">
                <h1>{{ header }}</h1>
                <human-body-info :pos="pos" :data="data"></human-body-info>
                <div class="buttons">
                    <button @click="handleBtnClimbClick" class="down btn" :disabled="pos === 0 || !vidStopped"></button>
                    <button @click="handleBtnClimbClick" class="up btn" :disabled="pos === 5 || !vidStopped"></button>
                </div>
            </div>
        </transition>
        
    </div>

</template>


<script>

import HumanBodyInfo from '@/components/human-body/HumanBodyInfo'
import HumanBodyGraph from '@/components/human-body/HumanBodyGraph'

import gsap from 'gsap';
import { TweenMax } from 'gsap'

export default {

    name: 'HumanBodyExperience',

    components: {
        HumanBodyInfo,
        HumanBodyGraph
    },

    data() {
        return {
            componentName: this.$options.name + ' View',
            header: '',
            borderColor: '',
            pos: 0,
            playing: true,
            vidStopped: false,
            climbing: 'up',
            particleCount: 30,
            particleClass: ['par1','par2'],
            posClass: ['oneP','twoP','threeP','fourP','fiveP','zeroP'],
            data: [
                {
                    title: 'Sea Level',
                    color: '#f4be1d',
                    ft: '0',
                    m: '0',
                    oxygen: '100%',
                    bpm: '64',
                    info: 'Moving around at sea level probably feels comfortable to you. That’s because the air is denser at lower elevations. Brace yourself: Things are about to get harder.'
                },
                {
                    title: 'High Altitude',
                    color: '#fff84c',
                    ft: '5,000',
                    m: '1,524',
                    oxygen: '82%',
                    bpm: '70',
                    info: 'Oxygen levels drop the higher you go. To supply your organs and muscles with enough oxygen, your heart must work harder. It will beat faster with greater force per beat. You will breathe harder. Dehydration, fatigue, and headaches are common.'
                },
                {
                    title: 'Very High Altitude',
                    color: '#53e13f',
                    ft: '11,500',
                    m: '3,505',
                    oxygen: '65%',
                    bpm: '78',
                    info: 'Your body will start to divert blood to critical organs and climbing muscles. This slows your digestion and kills your appetite. More than three-quarters of mountaineers experience vomiting or nausea. The change in pressure may affect your eyes—retinal bleeding leaves some climbers seeing red spots.'
                },
                {
                    title: 'Extreme Altitude',
                    color: '#22c0f5',
                    ft: '18,000',
                    m: '5,486',
                    oxygen: '50%',
                    bpm: '85',
                    info: 'Your breathing will become labored. You may develop a persistent cough. Your lung’s arteries will constrict, increasing pulmonary blood pressure. Fluid may now leak into your lung’s tissues. You are at risk of a condition called high-altitude pulmonary edema (HAPE).'
                },
                {
                    title: 'Death Zone',
                    color: '#2f4be3',
                    ft: '26,000',
                    m: '7,925',
                    oxygen: '36%',
                    bpm: '110',
                    info: 'Once you\'ve passed 8,000 meters (26,000 feet) you\'ve entered the "death zone.” The level of oxygen here is insufficient to sustain human life for an extended period. Your body is beginning to break down. Capillaries can leak and fluid can pool in your brain, a condition called high-altitude cerebral edema (HACE).'
                },
                {
                    title: 'Everest Summit',
                    color: '#6640f8',
                    ft: '29,032',
                    m: '8,849',
                    oxygen: '30%',
                    bpm: '123',
                    info: 'Congratulations! You\'ve made it to the summit. But remember, you still have to make it back down. Less oxygen in your blood means less oxygen in your brain. Your ability to think clearly and make good decisions diminishes by the minute. Your body is under intense stress.'
                },
            ]
        };
    },

    computed: {
        
    },
    
    created() {
        
    },
    
    mounted() {
        this.$refs.vid.addEventListener('timeupdate',this.handleVidTime)
        this.$refs.vid.currentTime = 0
        this.$refs.vid.play()
        this.borderColor = this.data[this.pos].color
        this.$refs.borderColor.style.borderTop = '5px solid ' + this.borderColor
        this.$refs.borderColor.style.borderBottom = '5px solid ' + this.borderColor
        
        gsap.from([
            this.$refs.particles
        ], 2.5,{
            delay: 2.5,
            autoAlpha: 0
        });

        for (let i = 0; i < this.particleCount; i++) {
			let par = document.createElement('div');
			par.setAttribute('class','par');
			par.classList.add(this.particleClass[i%2]);
            par.classList.add(this.posClass[i%6]);
			this.$refs.particles.appendChild(par);
            gsap.set([
                par
            ],{
                top: this.getRandom(-10,this.$refs.particles.offsetHeight),
                left: this.getRandom(-10,this.$refs.particles.offsetWidth),
                scale: this.getRandom(.7,1.1),
                transformOrigin: '50% 50%'
            })
		}

        let el = this
        let t_out = setTimeout(function(){
            clearTimeout(t_out)
            el.showVidCover()
        },50)

        window.addEventListener('resize',function(){
            el.showVidCover()
        })
    },
    
    methods: {
        /**
         * @function getRandom
         * @description
         * 
         */
        getRandom(min,max) {
            return Math.random()*(max-min)+min
        },
        /**
         * @function handleBtnClimbClick
         * @description
         * @param {Event} e
         */
        handleBtnClimbClick(e) {
            this.playing = true
            this.vidStopped = false
            switch(e.target.classList[0]) {
                case 'up':
                    this.climbing = 'up'
                    this.pos++
                    this.$refs.vid.play()
                    break
                case 'down':
                    this.climbing = 'down'
                    this.pos--
                    this.$refs.vid.play()
                    break
            }
            gsap.to([
                    this.$refs.particles
                ], 2.5,{
                    delay: 1.5,
                    autoAlpha: 0,
                    yoyo:true,
                    repeat: 1
                })
                let visible
            let el = ''

            this.climbing === 'up' ? visible = 0 : visible = 1

            if(this.pos === 0) {
                if(this.climbing === 'down') {
                    el = '.oneP'
                }
            }
            if(this.pos === 1) {
                el = '.oneP'
                if(this.climbing === 'down') {
                    el = '.twoP'
                }
            }
            if(this.pos === 2) {
                el = '.twoP'
                if(this.climbing === 'down') {
                    el = '.threeP'
                }
            }
            if(this.pos === 3) {
                el = '.threeP'
                if(this.climbing === 'down') {
                    el = '.fourP'
                }
            }
            if(this.pos === 4) {
                el = '.fourP'
                if(this.climbing === 'down') {
                    el = '.fiveP'
                }
            }
            if(this.pos === 5) {
                el = '.fiveP'
            }

            TweenMax.staggerTo([
                el
            ], .8,{
                delay: 7,
                opacity: visible,
                ease: 'power1.Out'
            },.8)
        },
        /**
         * @function handleVidTime
         * @description
         * 
         */
        handleVidTime() {
            if(this.climbing === 'up') {
                if(this.pos === 0 && this.$refs.vid.currentTime >= 3.5) {
                    this.playing = false
                    this.handleUI()
                }
                if(this.pos === 0 && this.$refs.vid.currentTime > 5 && this.$refs.vid.currentTime < 5.5) {
                    this.$refs.vid.pause()
                    this.vidStopped = true
                }
                if(this.pos === 1 && this.$refs.vid.currentTime >= 12) {
                    this.playing = false
                    this.handleUI()
                }
                if(this.pos === 1 && this.$refs.vid.currentTime > 18 && this.$refs.vid.currentTime < 18.5) {
                    this.$refs.vid.pause()
                    this.vidStopped = true
                }
                if(this.pos === 2 && this.$refs.vid.currentTime >= 26) {
                    this.playing = false
                    this.handleUI()
                }
                if(this.pos === 2 && this.$refs.vid.currentTime > 32 && this.$refs.vid.currentTime < 32.5) {
                    this.$refs.vid.pause()
                    this.vidStopped = true
                }
                if(this.pos === 3 && this.$refs.vid.currentTime >= 40) {
                    this.playing = false
                    this.handleUI()
                }
                if(this.pos === 3 && this.$refs.vid.currentTime > 46 && this.$refs.vid.currentTime < 46.5) {
                    this.$refs.vid.pause()
                    this.vidStopped = true
                }
                if(this.pos === 4 && this.$refs.vid.currentTime >= 54) {
                    this.playing = false
                    this.handleUI()
                } 
                if(this.pos === 4 && this.$refs.vid.currentTime > 60 && this.$refs.vid.currentTime < 60.5) {
                    this.$refs.vid.pause()
                    this.vidStopped = true

                }
                if(this.pos === 5 && this.$refs.vid.currentTime >= 68) {
                    this.playing = false
                    this.handleUI()
                }
                if(this.pos === 5 && this.$refs.vid.currentTime > 72 && this.$refs.vid.currentTime < 72.5) {
                    this.$refs.vid.pause()
                    this.vidStopped = true
                }
            }
            if(this.climbing === 'down') {
                if(this.pos === 0 && this.$refs.vid.currentTime > 23 && this.$refs.vid.currentTime < 23.5) {
                    this.$refs.vid.currentTime = 2
                    this.climbing = 'up'
                }
                if(this.pos === 1 && this.$refs.vid.currentTime > 37 && this.$refs.vid.currentTime < 37.5) {
                    this.$refs.vid.currentTime = 9
                    this.climbing = 'up'
                }
                if(this.pos === 2 && this.$refs.vid.currentTime > 51 && this.$refs.vid.currentTime < 51.5) {
                    this.$refs.vid.currentTime = 23
                    this.climbing = 'up'
                }
                if(this.pos === 3 && this.$refs.vid.currentTime > 65 && this.$refs.vid.currentTime < 65.5) {
                    this.$refs.vid.currentTime = 37
                    this.climbing = 'up'
                }
                if(this.pos === 4 && this.$refs.vid.currentTime > 76 && this.$refs.vid.currentTime < 76.5) {
                    this.$refs.vid.currentTime = 51
                    this.climbing = 'up'
                }
            }
        },
        /**
         * @function handleUI
         * @description
         * 
         */
        handleUI() {
            this.header = this.data[this.pos].title
            this.intro = false
        },
        /**
         * @function showVidCover
         * @description
         * 
         */
        showVidCover() {
            if(window.innerWidth >= this.$refs.cover.offsetWidth) {
                this.$refs.cover.style.opacity = 1
            } else {
                this.$refs.cover.style.opacity = 0
            }
        }
    },

    updated() {
        const { borderColor } = this.$refs;

        this.borderColor = this.data[this.pos].color

        gsap.to([
            borderColor
        ], 14,{
            borderTop: '5px solid ' + this.borderColor,
            borderBottom: '5px solid ' + this.borderColor,
            ease: 'power1.inOut'
        })
    },

    beforeDestroy() {
        this.$refs.vid.removeEventListener('timeupdate',this.handleVidTime)
    }
    
}

</script>


<style lang="scss">

.human-body-experience {
    background: white;
    position: absolute;
    width: 100%;
    height: 100%;

    & img {
        position: absolute;
        right: -10px;
        bottom: 80px;
        width: 125px;
    }

    & .particlesContainer {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        opacity: .7;

        & .particles {
            position: relative;
            width: 350px;
            height: 100px;
            margin-top: 80px;

            @media (min-width: 576px) { // RED (SM)
                width: 500px;
                height: 180px;
            }
            @media (min-width: 768px) { // GREEN (MD)

            }
            @media (min-width: 992px) { // BLUE (LG)

            }
            @media (min-width: 1200px) { // YELLOW (XL)

            }
            @media (min-width: 1400px) { // PURPLE (XXL)

            }

            & .par {
                background: transparent url('../../assets/images/human-body/img-particle.webp') 0 0 no-repeat;
                background-size: 10px 20px;
                width: 10px;
                height: 10px;
                position: absolute;
            }

            & .par1 {
                background-position: 0 0;
            }

            & .par2 {
                background-position: 0 -10px;
            }
        }
    }

    & .bordColor {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    & .cover {
        background: linear-gradient(to bottom,  rgba(199,224,241,0) 5%,rgba(199,224,241,0) 17%,rgba(242,252,253,0) 85%,rgba(242,252,253,1) 95%);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    & .vidFull {
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        overflow: hidden;

        & .vidWrap {
            position: relative;
            height: 100%;
            border: 1px solid white;
            

            & .vid {
                width: auto;
                height: 95%;
                margin-right: 15px;

                @media screen and (min-height:1090px) and (orientation:landscape) {
                    width: auto;
                    height: 100%;
                }

                @media (min-width: 576px) { // RED (SM)
                    margin-right: 0;
                }
                @media (min-width: 768px) { // GREEN (MD)

                }
                @media (min-width: 992px) { // BLUE (LG)

                }
                @media (min-width: 1200px) { // YELLOW (XL)

                }
                @media (min-width: 1400px) { // PURPLE (XXL)

                }
            }

            & .cover {
                background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 20%,rgba(255,255,255,0) 80%,rgba(255,255,255,1) 100%);
                position: absolute;
                width: 100%;
                height: 100%;
                opacity: 0;
            }
        }

    }

    & h1 {
        position: relative;
        background: linear-gradient(to bottom, #4167a5, #569cce);
        font-size: 28px;
        font-weight: $font-weight-medium;
        margin: 25px 0 0;
        user-select: none;
        display: inline-block;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
        text-transform: uppercase;
        line-height: 28px;
        letter-spacing: 1px;

        @media (min-width: 576px) { // RED (SM)
            margin: 40px 0 0;
            font-size: 46px;
            letter-spacing: 3px;
            line-height: 35px;
        }
        @media (min-width: 768px) { // GREEN (MD)

        }
        @media (min-width: 992px) { // BLUE (LG)

        }
        @media (min-width: 1200px) { // YELLOW (XL)

        }
        @media (min-width: 1400px) { // PURPLE (XXL)

        }
    }

    & .buttons {
        background-image: url('../../assets/images/human-body/img-buttons-bg.webp');
        background-size: 140px 60px;
        position: absolute;
        bottom: 112px;
        right: 15px;
        width: 140px;
        height: 60px;

        @media (min-width: 576px) { // RED (SM)
            right: 39px;
        }
        @media (min-width: 768px) { // GREEN (MD)
            
        }
        @media (min-width: 992px) { // BLUE (LG)
            bottom: 30px;
        }
        @media (min-width: 1200px) { // YELLOW (XL)

        }
        @media (min-width: 1400px) { // PURPLE (XXL)

        }

        & .btn {
            border: none;
            width: 70px;
            height: 60px;
            cursor: pointer;
            display: inline-block;

            &[disabled] {
                opacity: .2;
                cursor: unset;
            }
        }

        & .down {
            background: transparent url('../../assets/images/human-body/img-buttons.webp') 0 0 no-repeat;
            background-size: 140px 60px;
        }

        & .up {
            background: transparent url('../../assets/images/human-body/img-buttons.webp') 0 0 no-repeat;
            background-size: 140px 60px;
            background-position-x: -70px;
        }
    }

}

</style>